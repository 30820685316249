import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Avatar, Box, Button, Checkbox, Container, FormControlLabel, Paper, TextField, Typography,
} from '@mui/material';

import Authentication from '../../../services/Authentication';
import AdminContext from '../AdminContext';

export default function Login({ onLogin }) {
  const { localization } = useContext(AdminContext);
  const local = localization.login;
  const [remember, setRemember] = useState(localStorage.getItem('remember') === 'true');
  const [email, setEmail] = useState(localStorage.getItem('remember') === 'true' ? localStorage.getItem('email') : '');
  const [password, setPassword] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('remember', remember.toString());
    if (email) {
      if (remember) {
        localStorage.setItem('email', email);
      }
      if (password) {
        Authentication.signIn(email, password, (result) => {
          const { user, error } = result;
          if (error) {
            // eslint-disable-next-line no-alert
            window.alert(error);
          } else if (user) {
            onLogin(user);
          }
        });
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper sx={{ mt: 16, p: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, backgroundColor: 'primary.main' }}><LockOpenIcon /></Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>{local.signIn}</Typography>
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              fullWidth
              autoFocus
              required
              color="secondary"
              margin="dense"
              id="email"
              name="email"
              type="email"
              label={local.email}
              value={email}
              // error={alert && alert.code === 404}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              fullWidth
              color="secondary"
              margin="dense"
              id="password"
              name="password"
              type="password"
              label={local.password}
              value={password}
              // error={alert && alert.code === 401}
              onChange={(e) => setPassword(e.target.value)}

            />
            <FormControlLabel control={<Checkbox checked={remember} onChange={(e) => setRemember(e.target.checked)} />} label={local.remember} />
            <Button fullWidth sx={{ mt: 2 }} color="primary" variant="contained" type="submit">{local.signIn}</Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};
