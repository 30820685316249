import React, {
  useRef, useState, useEffect,
} from 'react';
import moment from 'moment';
import 'moment/locale/de';
import 'moment-timezone';

import Database from '../../services/Database';
import isSignage from '../../functions/isSignage';

import RoomsLayout from './RoomsLayout';

moment.locale('de');
moment.tz.setDefault('Europe/Vienna');

const calculateWeeks = (day) => {
  const result = [];
  for (let week = -1; week < 4; week += 1) {
    const days = [];
    const startOf = moment(day).startOf('week').add(week, 'w');
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek += 1) {
      days.push({ id: `${week}-${dayOfWeek}`, date: moment(startOf).add(dayOfWeek, 'd') });
    }
    result.push({ id: week, startOf, days });
  }
  return result;
};

const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export default function App() {
  const events = useRef();
  const playlist = useRef();
  const nuki = useRef();
  const nukiStates = useRef();
  const playlistTimer = useRef({ index: 0, timer: -1 });
  const day = useRef(new Date());
  const weeks = useRef(calculateWeeks(day.current));
  const eventCurrent = useRef();
  const eventNext = useRef();
  const eventPrevious = useRef();
  const alerts = useRef();
  const alertTimer = useRef({});
  const [time, setTime] = useState(new Date());
  const [offline, setOffline] = useState(false);
  const occupiedlabel = useRef();
  const occupiedglobal = useRef();

  useEffect(() => {
    const unsubscribe = {};
    unsubscribe.events = Database.onValue('events', (data) => { events.current = data; });
    unsubscribe.playlist = Database.onValue('playlist', (data) => { playlist.current = Object.values(data).sort((a, b) => (a.sort || 0) - (b.sort || 0)); });
    unsubscribe.offline = Database.onValue('settings/offline', (data) => { setOffline(data); });
    unsubscribe.occupiedlabel = Database.onValue('settings/occupiedlabel', (data) => { occupiedlabel.current = data; });
    unsubscribe.occupiedglobal = Database.onValue('settings/occupiedglobal', (data) => { occupiedglobal.current = data; });
    unsubscribe.nuki = Database.onValue('nuki', (data) => { nuki.current = data; });
    unsubscribe.nukiStates = Database.onValue('settings/nuki/states', (data) => {
      if (!data) return;
      const states = {};
      Object.values(data).forEach((item) => { states[item.state] = item; });
      nukiStates.current = states;
    });
    return () => Object.keys(unsubscribe).forEach((key) => unsubscribe[key]());
  }, []);

  useEffect(() => {
    const updateDayAndWeek = (newTime) => {
      if (!moment(newTime).isSame(moment(day.current), 'day')) {
        day.current = newTime;
        weeks.current = calculateWeeks(newTime);
      }
    };

    const updateEvents = (newTime) => {
      if (!events.current) return;

      const currentEvents = Object.values(events.current).filter(
        (event) => moment(event.start).isBefore(moment(newTime)) && moment(event.end).isAfter(moment(newTime)),
      );
      const previousEvents = Object.values(events.current)
        .filter((event) => moment(event.end).isBefore(moment(newTime)))
        .sort((a, b) => moment(b.start).diff(moment(a.start)));
      const nextEvents = Object.values(events.current)
        .filter((event) => moment(event.start).isAfter(moment(newTime)))
        .sort((a, b) => moment(a.start).diff(moment(b.start)));

      eventCurrent.current = currentEvents.length > 0 ? currentEvents[0] : undefined;
      eventPrevious.current = previousEvents.length > 0 ? previousEvents[0] : undefined;
      eventNext.current = nextEvents.length > 0 ? nextEvents[0] : undefined;
    };

    const updatePlaylistTimer = () => {
      if (!playlist.current) return;

      playlistTimer.current.timer += 1;
      if (playlistTimer.current.timer > playlist.current[playlistTimer.current.index].duration) {
        playlistTimer.current = { timer: 0, index: playlistTimer.current.index + 1 >= playlist.current.length ? 0 : playlistTimer.current.index + 1 };
      }
    };

    const updateAlerts = () => {
      const newAlerts = [];
      if (occupiedlabel.current && eventCurrent.current) {
        if (occupiedglobal.current || !isSignage(playlist.current, playlistTimer.current)) {
          newAlerts.push(occupiedlabel.current);
        }
      }
      if (nuki.current && nukiStates.current) {
        const activeKeys = [];
        Object.keys(nuki.current)
          .sort((a, b) => nukiStates.current[nuki.current[a].state].sort - nukiStates.current[nuki.current[b].state].sort)
          .forEach((group) => {
            const key = nuki.current[group].state;
            if (nukiStates.current[key].alert) {
              activeKeys.push(key);
              alertTimer.current[key] = alertTimer.current[key] ? alertTimer.current[key] + 1 : 1;
            }
          });
        Object.keys(alertTimer.current).forEach((key) => {
          if (activeKeys.includes(key)) {
            if (alertTimer.current[key] >= parseInt(nukiStates.current[key].timer, 10)) {
              newAlerts.push(nukiStates.current[key].alertlabel);
            }
          } else {
            delete alertTimer.current[key];
          }
        });
      }

      if (!alerts.current || !arraysAreEqual(alerts.current, newAlerts)) {
        alerts.current = newAlerts.length > 0 ? newAlerts : undefined;
      }
    };

    const calculateTime = () => {
      const newTime = new Date();

      updateDayAndWeek(newTime);
      updateEvents(newTime);
      updatePlaylistTimer();
      updateAlerts();
      setTime(newTime);
    };

    const interval = setInterval(calculateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <RoomsLayout
      alerts={alerts.current}
      offline={offline}
      eventCurrent={eventCurrent.current}
      eventNext={eventNext.current}
      eventPrevious={eventPrevious.current}
      events={events.current}
      time={time}
      weeks={weeks.current}
      playlist={playlist.current}
      playlistTimer={playlistTimer.current}
      nuki={nuki.current}
      nukiStates={nukiStates.current}
    />
  );
}
