import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@mui/material';

import AlertScreen from '../../../components/AlertScreen';
import Info from '../../../components/Info';
import CalenderMonth from '../../../components/CalenderMonth';

export default function Calendar({
  alerts,
  eventCurrent,
  eventNext,
  eventPrevious,
  events,
  time,
  weeks,
}) {
  return (
    <Container
      disableGutters
      maxWidth="false"
      sx={{
        borderLeft: '10px solid',
        borderRight: '10px solid',
        borderColor: eventCurrent ? 'primary.main' : 'secondary.main',
        backgroundColor: 'background.default',
        color: 'white',
      }}
    >
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={10} sx={{ p: 1 }}>
          <CalenderMonth time={time} eventCurrent={eventCurrent} eventNext={eventNext} events={events} weeks={weeks} />
          { alerts && <AlertScreen alerts={alerts} /> }
        </Grid>
        <Grid item xs={2}>
          <Info eventCurrent={eventCurrent} eventNext={eventNext} eventPrevious={eventPrevious} time={time} />
        </Grid>
      </Grid>
    </Container>
  );
}

Calendar.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string),
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({
    start: PropTypes.string,
  }),
  eventPrevious: PropTypes.shape({}),
  events: PropTypes.shape({}),
  time: PropTypes.instanceOf(Date).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Calendar.defaultProps = {
  alerts: undefined,
  events: undefined,
  eventCurrent: undefined,
  eventNext: undefined,
  eventPrevious: undefined,
};
