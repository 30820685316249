import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import LOGOS from '../constants/Logos';
import {
  readTitle, readOrganisation, readDate, readStart, readEnd, readAttendee, readDescription,
} from '../functions/readEvent';

export default function Event({
  event, label, sx,
}) {
  return (
    <Box key={event.uid} sx={{ mt: 0.5 }}>
      <Typography fontWeight={900} variant="caption">{label}</Typography>
      <Paper sx={{ p: 0.5, mr: 1, ...sx }}>
        <Typography component="div" variant="body1" fontWeight={900}>{readTitle(event)}</Typography>
        <Box display="flex">
          <Box sx={{ p: 0.5 }}>
            <img
              src={`${process.env.PUBLIC_URL}/icon/${LOGOS[readOrganisation(event)].path}`}
              alt={readOrganisation(event)}
              style={{ maxWidth: '64px', maxWeight: '64px' }}
            />
          </Box>
          <Box>
            <Typography component="div" variant="caption">{`${readDate(event)}: ${readStart(event)} Uhr - ${readEnd(event)} Uhr`}</Typography>
            <Typography component="div" variant="caption">{`Gebucht von ${readAttendee(event)}`}</Typography>
            <Typography component="div" variant="caption">{readDescription(event)}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

Event.propTypes = {
  label: PropTypes.string.isRequired,
  event: PropTypes.shape({
    uid: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    summary: PropTypes.string,
    description: PropTypes.string,
    attendee: PropTypes.shape({
      params: PropTypes.shape({
        CN: PropTypes.string,
      }),
    }),
  }).isRequired,
  sx: PropTypes.shape({}),
};

Event.defaultProps = {
  sx: undefined,
};
