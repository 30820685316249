import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import AlertScreen from '../../../components/AlertScreen';

export default function Signage({
  alerts,
  playlist,
  playlistTimer,
  nuki,
  nukiStates,
}) {
  return (
    <Box>
      <Box
        component="img"
        sx={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
        }}
        alt=""
        src={playlist[playlistTimer.index].url}
      />
      {alerts && (<AlertScreen alerts={alerts} />)}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        height: '50px',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
      }}
      >
        { nuki && nukiStates && Object.keys(nuki)
          .sort((a, b) => nukiStates[nuki[a].state].sort - nukiStates[nuki[b].state].sort)
          .map((group, index) => (
            <>
              {index !== 0 && (
                <Typography key={`&${group}`} fontWeight={900} fontSize={22}>&</Typography>
              )}
              <Typography
                key={group}
                color={nukiStates[nuki[group].state].color}
                fontWeight={900}
                fontSize={22}
              >
                {nukiStates[nuki[group].state].label}
              </Typography>
            </>
          ))}
      </Box>
    </Box>
  );
}

Signage.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string),
  playlist: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  playlistTimer: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }).isRequired,
  nuki: PropTypes.shape({}).isRequired,
  nukiStates: PropTypes.shape({}).isRequired,
};

Signage.defaultProps = {
  alerts: undefined,
};
