import React, { useContext, useState, useEffect } from 'react';
import {
  Box, Button, Card, CardActions, CardMedia, Container, Typography,
} from '@mui/material';

import FileUpload from '../../../components/FileUpload';
import Storage from '../../../services/Storage';
import Database from '../../../services/Database';
import AdminContext from '../AdminContext';

export default function DataStorage() {
  const { localization } = useContext(AdminContext);
  const local = localization.storage;
  const [storage, setStorage] = useState();

  useEffect(() => {
    const unsubscribeStorage = Database.onValue('/storage', (data) => setStorage(data));
    return () => {
      unsubscribeStorage();
    };
  }, []);

  const onFileUpload = async (file) => {
    const { key } = Database.push('/storage', { name: file.name, size: file.size });
    await Storage.uploadBytes(`/storage/${key}`, file);
    const url = await Storage.getDownloadURL(`/storage/${key}`);
    Database.update(`/storage/${key}`, { url });
  };

  const onFileDelete = async (key) => {
    await Storage.deleteObject(`/storage/${key}`);
    Database.delete(`/storage/${key}`);
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Box display="flex" flexWrap="wrap" gap={2} sx={{ mb: 5 }}>
        { storage && Object.keys(storage).map((key) => (
          <Card key={key}>
            <CardMedia
              sx={{ height: 140 }}
              image={storage[key].url}
              title={storage[key].name}
            />
            <Typography sx={{ ml: 1, mr: 1 }} variant="caption">{storage[key].name}</Typography>
            <CardActions>
              <Button size="small" onClick={() => onFileDelete(key)}>{local.delete}</Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <FileUpload accept="image" imageUrl="" onChange={onFileUpload} />
      </Box>
    </Container>
  );
}
