import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged,
  // sendPasswordResetEmail, confirmPasswordReset,
  signOut,
} from 'firebase/auth';

import app from '../config/Firebase';

const auth = getAuth(app);

export default class Authentication {
  static onUserChange(next) {
    return onAuthStateChanged(auth, (user) => next(user));
  }

  static signIn(email, password, next) {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(({ user }) => next({ user }))
          .catch((error) => next({ error }));
      })
      .catch((error) => next({ error }));
  }

  static signOut(next) {
    signOut(auth)
      .then(() => next())
      .catch((error) => next({ error }));
  }
}
