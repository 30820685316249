import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function FileUpload({
  accept,
  imageButton,
  hoverLabel,
  dropLabel,
  width,
  height,
  backgroundColor,
  imageUrl,
  imageStyle,
  onChange,
}) {
  const [labelText, setLabelText] = useState(hoverLabel);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (e.dataTransfer.files[0]) {
        onChange(e.dataTransfer.files[0]);
      }
    },
  };

  const handleChange = (event) => {
    if (event.target && event.target.files && event.target.files[0]) {
      onChange(event.target.files[0]);
    }
  };

  return (
    <>
      <input onChange={handleChange} accept={accept} id="file-upload" type="file" style={{ display: 'none' }} />
      <Typography
        component="label"
        htmlFor="file-upload"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dragEvents}
        sx={{
          cursor: 'pointer',
          textAlign: 'center',
          display: 'flex',
          '&:hover p,&:hover svg,& img': {
            opacity: 1,
          },
          '& p, svg': {
            opacity: 0.4,
          },
          '&:hover img': {
            opacity: 0.3,
          },
          ...(isDragOver && {
            '& img': {
              opacity: 0.3,
            },
            '& p, svg': {
              opacity: 1,
            },
          }),
        }}
      >
        <Box width={width} height={height} bgcolor={backgroundColor} sx={{ pointerEvents: 'none' }}>
          {imageButton && (
            <Box position="absolute" height={height} width={width}>
              <img alt="file upload" src={imageUrl} style={imageStyle} />
            </Box>
          )}
          {(!imageButton || isDragOver || isMouseOver) && (
            <Box
              height={height}
              width={width}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
              }}
            >
              <CloudUploadIcon fontSize="large" />
              <Typography>{labelText}</Typography>
            </Box>
          )}
        </Box>
      </Typography>
    </>
  );
}

FileUpload.propTypes = {
  accept: PropTypes.string.isRequired,
  imageButton: PropTypes.bool,
  hoverLabel: PropTypes.string,
  dropLabel: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  imageStyle: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  imageButton: false,
  hoverLabel: 'Klicken oder ziehen, um Datei hochzuladen',
  dropLabel: 'Datei hier ablegen',
  width: '600px',
  height: '100px',
  backgroundColor: '#fff',
  imageStyle: {
    height: 'inherit',
  },
};
