import React, { useContext, useState, useEffect } from 'react';
import {
  Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField,
  Typography,
} from '@mui/material';

import { Add, Delete } from '@mui/icons-material';
import Database from '../../../services/Database';
import AdminContext from '../AdminContext';

export default function Playlist() {
  const { localization } = useContext(AdminContext);
  const local = localization.playlist;
  const [playlist, setPlaylist] = useState();
  const [storage, setStorage] = useState();

  useEffect(() => {
    const unsubscribePlaylist = Database.onValue('/playlist', (data) => setPlaylist(data));
    const unsubscribeStorage = Database.onValue('/storage', (data) => setStorage(data));
    return () => {
      unsubscribePlaylist();
      unsubscribeStorage();
    };
  }, []);

  const onChange = (key, field, value) => Database.update(`/playlist/${key}`, { [field]: value });
  const onDelete = (key) => Database.delete(`/playlist/${key}`);
  const onPush = () => Database.push('/playlist', { sort: 1, duration: 0, type: 'events' });

  return (
    <Container sx={{
      mt: 2, display: 'flex', flexDirection: 'column', gap: 1,
    }}
    >
      {playlist && Object.keys(playlist)
        .sort((a, b) => (playlist[a].sort || 0) - (playlist[b].sort || 0))
        .map((key) => (
          <Paper key={key} sx={{ p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  type="number"
                  label={local.sort}
                  value={playlist[key].sort}
                  onChange={(e) => onChange(key, 'sort', e.target.value)}
                  sx={{ mt: 1 }}
                />
                <TextField
                  fullWidth
                  type="number"
                  label={local.duration}
                  value={playlist[key].duration}
                  onChange={(e) => onChange(key, 'duration', e.target.value)}
                  sx={{ mt: 1 }}
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="type-label">{local.type}</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={playlist[key].type}
                    label={local.type}
                    onChange={(e) => onChange(key, 'type', e.target.value)}
                  >
                    <MenuItem value="events">{local.events}</MenuItem>
                    <MenuItem value="storage">{local.storage}</MenuItem>
                  </Select>
                </FormControl>
                { playlist[key].type === 'storage' && (
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="storage-label">{local.storage}</InputLabel>
                    <Select
                      labelId="storage-label"
                      id="storage"
                      value={playlist[key].url}
                      label={local.storage}
                      onChange={(e) => onChange(key, 'url', e.target.value)}
                    >
                      { storage && Object.keys(storage).map((id) => (
                        <MenuItem key={id} value={storage[id].url}>{storage[id].name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={9}>
                <Box
                  component="img"
                  sx={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: 'auto',
                    height: 'auto',
                    margin: 'auto',
                  }}
                  alt=""
                  src={playlist[key].url}
                />
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="row-reverse" sx={{ mt: 1 }}>
              <Button variant="contained" onClick={() => onDelete(key)}>
                <Delete sx={{ mr: 1 }} />
                {local.delete}
              </Button>
            </Box>
          </Paper>
        ))}
      <Paper>
        <Button fullWidth variant="outlined" onClick={onPush}>
          <Add sx={{ mr: 1 }} />
          <Typography>{local.push}</Typography>
        </Button>
      </Paper>
    </Container>
  );
}
