import React from 'react';
import PropTypes from 'prop-types';
import Clock from 'react-clock';
import moment from 'moment';
import Box from '@mui/material/Box';
import 'react-clock/dist/Clock.css';

import LOGOS from '../constants/Logos';

import CircularProgressWithLabel from './CircularProgressWithLabel';
import { readOrganisation } from '../functions/readEvent';

export default function InfoClock({ eventCurrent, eventNext, time }) {
  const sxBox = {
    backgroundImage: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '18.8%',
    mr: 1,
  };

  const sxClock = {
    backgroundColor: 'background.paper',
    borderRadius: '90px',
    mt: 'auto',
    mb: 'auto',
  };

  const render = () => {
    let color;
    let countdown;
    let organisation;
    let hours;
    let minutes;
    let seconds;
    let value;
    let max = 30 * 60;
    if (eventCurrent) {
      color = 'primary';
      countdown = true;
      organisation = readOrganisation(eventCurrent);
      const duration = moment.duration(moment(eventCurrent.end).diff(moment(time)));
      hours = parseInt(duration.asHours(), 10);
      minutes = parseInt(duration.asMinutes(), 10) % 60;
      seconds = parseInt(duration.asSeconds(), 10) % 60;
      max = parseInt(moment.duration(moment(eventCurrent.start).diff(moment(eventCurrent.end))).asSeconds(), 10);
    } else if (eventNext) {
      const duration = moment.duration(moment(eventNext.start).diff(moment(time)));
      hours = parseInt(duration.asHours(), 10);
      minutes = parseInt(duration.asMinutes(), 10) % 60;
      seconds = parseInt(duration.asSeconds(), 10) % 60;
      if (hours === 0 && minutes <= 30) {
        color = 'secondary';
        countdown = true;
        organisation = readOrganisation(eventNext);
      }
    }
    if (countdown) {
      value = (((hours * 60) + minutes) * 60) + seconds;
    }
    return countdown ? (
      <Box display="flex" justifyContent="center" sx={sxBox}>
        <Box
          sx={{
            backgroundImage: `url(/logo/${LOGOS[organisation].path})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '128px',
            width: '128px',
            mt: 'auto',
            mb: 'auto',
            mr: 2,
          }}
        />
        <CircularProgressWithLabel
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          value={value}
          max={max}
          color={color}
        />
      </Box>
    ) : (
      <Box display="flex" justifyContent="center" sx={sxBox}>
        <Box sx={sxClock}>
          <Clock value={time} />
        </Box>
      </Box>
    );
  };
  return render();
}

InfoClock.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({}),
};

InfoClock.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
};
