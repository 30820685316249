import React, { useContext, useState, useEffect } from 'react';
import {
  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Typography,
} from '@mui/material';

import { Refresh, ExpandMore } from '@mui/icons-material';
import Database from '../../../services/Database';
import AdminContext from '../AdminContext';

export default function NukiApiKey() {
  const { localization } = useContext(AdminContext);
  const local = localization.nuki;
  const [apikey, setApikey] = useState();

  useEffect(() => {
    const unsubscribe = {};
    unsubscribe.apikey = Database.onValue('/config/nuki/apikey', (data) => setApikey(data));
    return () => Object.keys(unsubscribe).forEach((key) => unsubscribe[key]());
  }, []);

  const onNewApiKey = () => Database.update('/config/nuki', { apikey: crypto.randomUUID() });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{local.apikey}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="caption">{local.apikey}</Typography>
        <Typography>{apikey}</Typography>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" onClick={() => onNewApiKey()}>
          <Refresh sx={{ mr: 1 }} />
          {local.newApiKey}
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
