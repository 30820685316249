import moment from 'moment';

export const readDate = (event) => moment(event.start).format('DD.MM.yyyy');
export const readStart = (event) => moment(event.start).format('HH:mm');
export const readEnd = (event) => moment(event.end).format('HH:mm');
export const readAttendee = (event) => {
  const attendeeParamsCN = event.attendee ? event.attendee.params.CN.split('(')[0] : 'Rooms Admin';
  const firstname = attendeeParamsCN ? attendeeParamsCN.split(' ')[0] : '';
  const lastname = attendeeParamsCN ? attendeeParamsCN.split(' ')[1] : '';
  return `${firstname} ${lastname}`.length <= 15 ? `${firstname} ${lastname}` : `${firstname} ${lastname.charAt(0)}.`;
};
export const readTitle = (event) => (
  event.attendee
    ? event.summary.replace('(Medienraum)', '').replace(`${event.attendee.params.CN}: `, '')
    : event.summary.replace('(Medienraum)', '')
) || 'Kein Titel';
export const readDescription = (event) => event.description.replace('Spaces: Medienraum', '').replace('Notes: ', '') || 'Keine Beschreibung';
export const readOrganisation = (event) => {
  if (event.attendee?.params?.CN?.split('(')[1]) {
    return event.attendee.params.CN.split('(')[1].slice(0, -1);
  }
  return '-';
};
