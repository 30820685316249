import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../../components/Loading';
import Authentication from '../../services/Authentication';
import Database from '../../services/Database';
import AdminContext from './AdminContext';
import AdminLayout from './AdminLayout';

export default function App() {
  const [localization, setLocalization] = useState();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const adminContext = useMemo(() => ({
    user, localization,
  }), [user, localization]);

  useEffect(() => {
    const unsubscribe = {};
    unsubscribe.userChange = Authentication.onUserChange(setUser);
    unsubscribe.localization = Database.onValue('/settings/localization/de/admin', (data) => setLocalization(data));
    return () => Object.keys(unsubscribe).forEach((key) => unsubscribe[key]());
  }, []);

  const onLogin = (userAuth) => {
    setUser(userAuth);
    navigate('/admin');
  };

  const onLogout = () => Authentication.signOut(() => {
    setUser(null);
    navigate('/');
  });

  if (!localization) {
    return <Loading />;
  }

  return (
    <AdminContext.Provider value={adminContext}>
      <AdminLayout
        onLogin={onLogin}
        onLogout={onLogout}
      />
    </AdminContext.Provider>
  );
}
