import React from 'react';
import PropTypes from 'prop-types';

import isSignage from '../../functions/isSignage';
import Maintenance from '../maintenance/Maintenance';
import Signage from './signage/Signage';
import Calendar from './calendar/Calendar';

export default function RoomsLayout({
  alerts,
  eventCurrent,
  eventNext,
  eventPrevious,
  events,
  time,
  weeks,
  offline,
  playlist,
  playlistTimer,
  nuki,
  nukiStates,
}) {
  if (offline) {
    return <Maintenance />;
  }

  if (isSignage(playlist, playlistTimer)) {
    return (
      <Signage
        alerts={alerts}
        playlist={playlist}
        playlistTimer={playlistTimer}
        nuki={nuki}
        nukiStates={nukiStates}
      />
    );
  }

  return (
    <Calendar
      alerts={alerts}
      eventCurrent={eventCurrent}
      eventNext={eventNext}
      eventPrevious={eventPrevious}
      events={events}
      time={time}
      weeks={weeks}
    />
  );
}

RoomsLayout.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string),
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({
    start: PropTypes.string,
  }),
  eventPrevious: PropTypes.shape({}),
  events: PropTypes.shape({}),
  time: PropTypes.instanceOf(Date).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  offline: PropTypes.bool.isRequired,
  playlist: PropTypes.arrayOf(PropTypes.shape({})),
  playlistTimer: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }),
  nuki: PropTypes.shape({}),
  nukiStates: PropTypes.shape({}),
};

RoomsLayout.defaultProps = {
  alerts: undefined,
  events: undefined,
  eventCurrent: undefined,
  eventNext: undefined,
  eventPrevious: undefined,
  playlist: undefined,
  playlistTimer: undefined,
  nuki: undefined,
  nukiStates: undefined,
};
