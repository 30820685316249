import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import CalenderMonthEvent from './CalenderMonthEvent';

const SX_AREA = { width: '100%', height: '100%' };
const SX_HEADER = { height: '20px', p: 0, backgroundColor: 'background.default' };
const SX_ROW = { verticalAlign: 'top' };
const SX_DATE = { p: 0.5 };
const SX_DATE_TODAY = {
  p: 0.5, pr: 2, pl: 2, backgroundColor: 'white', color: 'black', borderRadius: 45,
};
const getSxCell = (dayOfWeek, day) => {
  const sx = {
    height: '18%',
    p: 0.5,
    textAlign: 'center',
    width: '11.9vw',
  };
  if (moment(dayOfWeek.date).isBefore(moment(day), 'day')) {
    sx.backgroundColor = 'background.dayPast';
  } else if (moment(dayOfWeek.date).isSame(moment(day), 'day')) {
    sx.border = '1px solid';
    sx.borderColor = 'white';
  } else if (!moment(dayOfWeek.date).isSame(moment(day), 'month')) {
    sx.backgroundColor = 'background.dayFuture';
  }
  return sx;
};

const DAYS_SHORT = ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'];

export default function CalenderMonth({
  eventCurrent,
  eventNext,
  events,
  time,
  weeks,
}) {
  return (
    <TableContainer sx={SX_AREA}>
      <Table sx={SX_AREA}>
        <TableBody>
          <TableRow sx={SX_HEADER}>
            {DAYS_SHORT.map((label) => (
              <TableCell key={label} sx={SX_HEADER}>
                <Typography variant="caption">{label}</Typography>
              </TableCell>
            ))}
          </TableRow>
          {weeks.map((week) => (
            <TableRow key={week.id} sx={SX_ROW}>
              { week.days.map((dayOfWeek) => (
                <TableCell key={dayOfWeek.id} sx={getSxCell(dayOfWeek, time)}>
                  <Typography variant="caption" fontWeight={900} sx={moment(dayOfWeek.date).isSame(moment(time), 'day') ? SX_DATE_TODAY : SX_DATE}>
                    {dayOfWeek.date.format('DD.MM')}
                  </Typography>
                  { Object.values(events)
                    .filter((event) => moment(dayOfWeek.date).isSame(moment(event.start), 'day'))
                    .sort((a, b) => moment(a.start).diff(moment(b.start)))
                    .map((event) => (
                      <CalenderMonthEvent
                        key={event.uid}
                        event={event}
                        eventCurrent={eventCurrent}
                        eventNext={eventNext}
                        time={time}
                      />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CalenderMonth.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({
    start: PropTypes.string,
  }),
  events: PropTypes.shape({}),
  weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CalenderMonth.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
  events: {},
};
