import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  AccordionDetails,
  AccordionActions,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';

import { SketchPicker } from 'react-color';
import AdminContext from '../AdminContext';

export default function NukiState({
  state, onChange, onTextChange, onDelete,
}) {
  const { localization } = useContext(AdminContext);
  const local = localization.nuki;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{state.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label={local.name}
              value={state.name}
              onChange={(e) => onTextChange('name', e.target.value)}
            />
            <TextField
              fullWidth
              size="small"
              label={local.label}
              value={state.label}
              onChange={(e) => onTextChange('label', e.target.value)}
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              size="small"
              label={local.group}
              value={state.group}
              onChange={(e) => onTextChange('group', e.target.value)}
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              size="small"
              label={local.state}
              value={state.state}
              onChange={(e) => onTextChange('state', e.target.value)}
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              size="small"
              type="number"
              label={local.sort}
              value={state.sort}
              onChange={(e) => onChange('sort', e.target.value)}
              sx={{ mt: 2 }}
            />
            <FormControlLabel
              fullWidth
              sx={{ mt: 2 }}
              label={local.alert}
              labelPlacement="end"
              control={(
                <Switch
                  checked={state.alert}
                  onChange={(e) => onChange('alert', e.target.checked)}
                  inputProps={{ 'aria-label': 'alert' }}
                />
                  )}
            />
            { state.alert && (
              <TextField
                fullWidth
                size="small"
                label={local.alertlabel}
                value={state.alertlabel}
                onChange={(e) => onChange('alertlabel', e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
            { state.alert && (
              <TextField
                fullWidth
                size="small"
                type="number"
                label={local.timer}
                value={state.timer}
                onChange={(e) => onChange('timer', e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <SketchPicker
              disableAlpha
              color={state.color}
              onChange={(color) => onChange('color', color.hex)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={onDelete}>
          <Delete sx={{ mr: 1 }} />
          {local.delete}
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

NukiState.propTypes = {
  state: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    alert: PropTypes.bool.isRequired,
    alertlabel: PropTypes.string,
    timer: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
