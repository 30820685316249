import React, { useContext, useState, useEffect } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import Database from '../../../services/Database';
import AdminContext from '../AdminContext';
import NukiState from './NukiState';

const newState = (local) => ({
  sort: 1, state: 'new', label: local.newState, color: '', alert: false, timer: 0,
});

export default function NukiStates() {
  const { localization } = useContext(AdminContext);
  const local = localization.nuki;
  const [states, setStates] = useState();

  useEffect(() => {
    const unsubscribe = {};
    unsubscribe.states = Database.onValue('/settings/nuki/states', (data) => setStates(data));
    return () => Object.keys(unsubscribe).forEach((key) => unsubscribe[key]());
  }, []);

  const onChange = (key, field, value) => Database.update(`/settings/nuki/states/${key}`, { [field]: value });
  const onTextChange = (key, field, value) => Database.update(`/settings/nuki/states/${key}`, { [field]: value });
  const onDelete = (key) => Database.delete(`/settings/nuki/states/${key}`);
  const onPush = () => Database.push('/settings/nuki/states', newState(local));

  return (
    <div>
      <Typography color="white" sx={{ mt: 1 }}>
        Nuki Status-Liste:
      </Typography>
      { states && (
        Object.keys(states)
          .sort((a, b) => (states[a].sort || 0) - (states[b].sort || 0))
          .map((key) => (
            <NukiState
              key={key}
              state={states[key]}
              onChange={(field, value) => onChange(key, field, value)}
              onTextChange={(field, value) => onTextChange(key, field, value)}
              onDelete={() => onDelete(key)}
            />
          ))
      )}
      <Paper sx={{ mb: 2 }}>
        <Button fullWidth variant="contained" onClick={onPush}>
          <Add sx={{ mr: 1 }} />
          <Typography>{local.push}</Typography>
        </Button>
      </Paper>
    </div>
  );
}
