import React from 'react';
import { Container } from '@mui/material';

import NukiApiKey from './NukiApiKey';
import NukiStates from './NukiStates';

export default function Nuki() {
  return (
    <Container sx={{ mt: 2 }}>
      <NukiApiKey />
      <NukiStates />
    </Container>
  );
}
