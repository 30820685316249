import {
  getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll,
} from 'firebase/storage';
import app from '../config/Firebase';

const storage = getStorage(app);

export default class Storage {
  static getDownloadURL(path) {
    return getDownloadURL(ref(storage, path));
  }

  static uploadBytes(path, data) {
    return uploadBytes(ref(storage, path), data);
  }

  static listAll(path) {
    return listAll(ref(storage, path));
  }

  static deleteObject(path) {
    return deleteObject(ref(storage, path));
  }
}
