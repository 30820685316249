import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Routes, useNavigate, useLocation, Navigate,
} from 'react-router-dom';
import {
  Button,
  Container, Paper, Tab, Tabs,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import LogoutIcon from '@mui/icons-material/Logout';
import PlaylistIcon from '@mui/icons-material/PlaylistAdd';
import KeyIcon from '@mui/icons-material/Key';

import AppStorage from './appstorage/AppStorage';
import Login from './login/Login';
import Nuki from './nuki/Nuki';
import Playlist from './playlist/Playlist';
import Settings from './settings/Settings';
import AdminContext from './AdminContext';

export default function AdminLayout({ onLogin, onLogout }) {
  const { user, localization } = useContext(AdminContext);
  const local = localization.menu;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!user) {
    return <Login onLogin={onLogin} />;
  }

  return (
    <Container disableGutters maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Button />
      <Paper>
        <Tabs
          scrollButtons
          allowScrollButtonsMobile
          value={pathname.split('/')[2] || ''}
          onChange={(e, value) => navigate(`${value}`)}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab value="" label={local.settings} icon={<SettingsIcon />} />
          <Tab value="playlist" label={local.playlist} icon={<PlaylistIcon />} />
          <Tab value="storage" label={local.storage} icon={<ImageIcon />} />
          <Tab value="nuki" label={local.nuki} icon={<KeyIcon />} />
          <Tab value="/" label={local.logout} icon={<LogoutIcon />} onClick={onLogout} />
        </Tabs>
      </Paper>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          pl: 1, pr: 1, flexGrow: 1, overflow: 'auto',
        }}
      >
        <Routes>
          <Route path="" element={<Settings />} />
          <Route path="playlist" element={<Playlist />} />
          <Route path="storage" element={<AppStorage />} />
          <Route path="nuki" element={<Nuki />} />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Routes>
      </Container>
    </Container>
  );
}

AdminLayout.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
