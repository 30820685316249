import React from 'react';
import Container from '@mui/material/Container';

import { Typography } from '@mui/material';

export default function Maintenance() {
  return (
    <Container
      disableGutters
      maxWidth="false"
      sx={{
        backgroundColor: 'red',
        color: 'white',
        height: '100vh',
      }}
    >
      <Typography variant="h1" sx={{ pt: '40vh', textAlign: 'center' }}>Wartungsarbeiten werden durchgeführt!</Typography>
      <Typography sx={{ pt: '5vh', textAlign: 'center', fontSize: '25px' }}>Bitte Aushang beachten. Für Buchungen an die SPÖ Burgenland wenden.</Typography>
    </Container>
  );
}
