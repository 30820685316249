import React, { useContext, useState, useEffect } from 'react';
import {
  Container, Divider, FormControlLabel, Grid, Paper, Switch, TextField,
  Typography,
} from '@mui/material';

import Database from '../../../services/Database';
import AdminContext from '../AdminContext';

export default function Playlist() {
  const { localization } = useContext(AdminContext);
  const local = localization.settings;
  const [offline, setOffline] = useState();
  const [occupiedlabel, setOccupiedlabel] = useState('');
  const [occupiedglobal, setOccupiedglobal] = useState();

  useEffect(() => {
    const unsubscribe = {};
    unsubscribe.occupiedlabel = Database.onValue('/settings/occupiedlabel', (data) => setOccupiedlabel(data));
    unsubscribe.occupiedglobal = Database.onValue('/settings/occupiedglobal', (data) => setOccupiedglobal(data));
    unsubscribe.offline = Database.onValue('/settings/offline', (data) => setOffline(data));
    return () => Object.keys(unsubscribe).forEach((key) => unsubscribe[key]());
  }, []);

  const onChange = (field, value) => Database.update('/settings', { [field]: value });

  return (
    <Container sx={{
      mt: 2, display: 'flex', flexDirection: 'column', gap: 1,
    }}
    >
      <Paper sx={{ p: 1 }}>
        <Typography variant="h1">Wartungsmodus</Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography>Im Wartungsmodus wird ein einfacher Wartungsbildschirm angezeigt</Typography>
        <FormControlLabel
          fullWidth
          sx={{ mt: 2 }}
          label={local.offline}
          labelPlacement="end"
          control={(
            <Switch
              checked={offline}
              onChange={(e) => onChange('offline', e.target.checked)}
              inputProps={{ 'aria-label': 'offline' }}
            />
              )}
        />
        <Typography variant="h1" sx={{ mt: 2 }}>Raum besetzt</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={local.occupiedlabel}
              value={occupiedlabel}
              onChange={(e) => onChange('occupiedlabel', e.target.value)}
              sx={{ mt: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              fullWidth
              sx={{ mt: 2 }}
              label={local.occupiedglobal}
              labelPlacement="end"
              control={(
                <Switch
                  checked={occupiedglobal}
                  onChange={(e) => onChange('occupiedglobal', e.target.checked)}
                  inputProps={{ 'aria-label': 'occupiedglobal' }}
                />
          )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
