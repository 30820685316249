import { initializeApp } from 'firebase/app';

const ENVS = {
  PROD: 'PROD',
  TEST: 'TEST',
};
const ENV = ENVS.PROD;

const app = initializeApp({
  apiKey: ENV === ENVS.PROD ? process.env.REACT_APP_API_KEY_PROD : process.env.REACT_APP_API_KEY_TEST,
  authDomain: ENV === ENVS.PROD ? process.env.REACT_APP_AUTH_DOMAIN_PROD : process.env.REACT_APP_AUTH_DOMAIN_TEST,
  databaseURL: ENV === ENVS.PROD ? process.env.REACT_APP_DATABASE_URL_PROD : process.env.REACT_APP_DATABASE_URL_TEST,
  projectId: ENV === ENVS.PROD ? process.env.REACT_APP_PROJECT_ID_PROD : process.env.REACT_APP_PROJECT_ID_TEST,
  storageBucket: ENV === ENVS.PROD ? process.env.REACT_APP_STORAGE_BUCKET_PROD : process.env.REACT_APP_STORAGE_BUCKET_TEST,
  messagingSenderId: ENV === ENVS.PROD ? process.env.REACT_APP_MESSAGING_SENDER_ID_PROD : process.env.REACT_APP_MESSAGING_SENDER_ID_TEST,
  appId: ENV === ENVS.PROD ? process.env.REACT_APP_APP_ID_PROD : process.env.REACT_APP_APP_ID_TEST,
});

export default app;
